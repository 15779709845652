import { secured } from '@/config/axios';

const resource = 'assets';

export default {
    get: id => secured.get(`${resource}/${id}`),
    update: (id, data) => secured.put(`${resource}/${id}`, data),
    getDetails: id => secured.get(`${resource}/${id}/details`),
    getFlexibility: params => secured.get(`${resource}/retrieve${params}`),
};
